<script>
	export let scheme;
    export let message;
    export let date;
</script>

{#if message != "reply-from-chat-history"}
<div class="flex items-center mb-[20px] max-w-full user-message">
    <div class="flex-none self-start flex flex-col items-center space-y-1 mr-[8px] ml-auto w-fit-content">
        <div class="rounded-full w-[30px] h-[30px]" />
    </div>
    <div>
        <div style="border-radius: 10px 3px 10px 10px; background-color: {scheme.userBubble}; color: {scheme.userBubbleText};" class="flex-1 p-[20px] rounded-lg mb-2 relative text-[14px] leading-[24px] font-normal">
            <div class="prose prose-sm" style="color: {scheme.userBubbleText};">{@html message}</div>
        </div>
        <div class="text-right text-[#718096] text-[12px] leading-[16px] font-normal">{date}</div>
    </div>
</div>
{/if}
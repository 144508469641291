export const convertLinksToTargetBlank = ( message ) => {
    // Convert the HTML content into DOM elements
    const parser = new DOMParser();
    const doc = parser.parseFromString(message, 'text/html');

    // Find all anchor elements and add the target attribute
    const anchorElements = doc.querySelectorAll('a');
    anchorElements.forEach(anchor => {
    anchor.setAttribute('target', '_blank');
    });

    // Convert the modified DOM back to HTML
    const modifiedMessage = doc.documentElement.innerHTML;

    return modifiedMessage
}

export function removeJSONBlockMarker(jsonString) {
    // Define the regex pattern to match the JSON block marker
    const regex = /^```json\s*([\s\S]*?)\s*```$/gm;
    // Replace the matched JSON block marker with an empty string
    return jsonString.replace('```json', '').replace('```', '')

}

// export function removeJSONBlockMarker(jsonString) {
//     // Define the regex pattern to match the JSON block marker
//     const regex = /^```json\s*([\s\S]*?)(?:```|$)/m;
//     // Replace the matched JSON block marker with the captured content
//     return jsonString.replace(regex, '$1');
// }
<script>
	import { fade } from 'svelte/transition';
    import emblaCarouselSvelte from "embla-carousel-svelte";
    import CarouselCard from "./CarouselCard.svelte";

    export let slides = [];
    export let isCarouselLoading = false;
    export let perPage = 2;
    export let startIndex = 0;
    export let controls = true;
    export let scheme;

    let currentIndex = startIndex;

	let emblaApi;
    let controller;

    $: pips = controller ? controller.innerElements : [];
    $: currentPerPage = controller ? controller.perPage : perPage;
    $: reactiveSlides = slides;
    $: loading = isCarouselLoading;

    // Conditionally hide controls if there is only one slide
    $: controls = reactiveSlides.length > 1;

	function onInit(event) {
		emblaApi = event.detail
		// console.log(emblaApi.slideNodes()) // Access API
	}

    export function isDotActive(currentIndex, dotIndex) {
        if (currentIndex < 0) currentIndex = pips.length + currentIndex;
        return (
            currentIndex >= dotIndex * currentPerPage &&
            currentIndex < dotIndex * currentPerPage + currentPerPage
        );
    }

    export function left() {
        emblaApi.scrollPrev()
    }

    export function right() {
        emblaApi.scrollNext()
    }
</script>

<div
    class="carousel bg-white rounded-[10px] rounded-tl-[3px]
border-t-2 shadow-[0_2px_4px_2px_rgba(0,0,0,0.06)]"
    style="border-color: {scheme.header}; left: 15%;  padding: 15px"
>
    <div class="embla" use:emblaCarouselSvelte on:emblaInit="{onInit}">
        <div class="embla__container">
            {#each reactiveSlides as slide, index}
                <div 
					key={index} 
					class={`embla__slide item`} 
					in:fade={{ duration: 600 }}
					out:fade={{ duration: 600 }}>
                    <CarouselCard key={slide} {...slide} category={slide?.category} {scheme} />
                </div>
            {/each}
			{#if loading}
				<div class={`embla__slide item`} in:fade={{ duration: 600 }}
				out:fade={{ duration: 600 }}>
					<CarouselCard loading={true} {scheme}  />
				</div>
			{/if}
        </div>
    </div>

    {#if controls}
    <button class="left" on:click={left} aria-label="left">
		  <slot name="left-control"></slot>
	  </button>
	  <button class="right" on:click={right} aria-label="right">
		  <slot name="right-control"></slot>
	  </button>
    {/if}
</div>

<style>
	.embla {
        overflow: hidden;
    }
    .embla__container {
        display: flex;
    }
    .embla__slide {
        flex: 0 0 80%;
        min-width: 0;
    }

    .carousel {
        position: relative;
        width: 85%;
        justify-content: center;
        align-items: center;
    }

    button {
        position: absolute;
        width: 40px;
        height: 40px;
        top: 50%;
        z-index: 50;
        margin-top: -20px;
        border: none;
        background-color: transparent;
    }
    button:focus {
        outline: none;
    }

    .left {
        left: 2vw;
    }

    .right {
        right: 2vw;
    }

    :root {
        --primary: #3498db; /* Change to your desired color */
    }
</style>

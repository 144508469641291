<script>
    export let width = '100%';
    export let height = '20px';
    export let borderRadius = '8px';
  </script>
  
  <style>
    .skeleton {
      display: inline-block;
      background-color: #B2BEB5;
      width: var(--width);
      height: var(--height);
      border-radius: var(--border-radius);
      position: relative;
      overflow: hidden;
    }
  
    .skeleton::before {
      content: '';
      display: block;
      position: absolute;
      top: 0;
      left: -150%;
      height: 100%;
      width: 150%;
      background: linear-gradient(90deg, transparent, rgba(255, 255, 255, 0.5), transparent);
      animation: shimmer 1.5s infinite;
    }
  
    @keyframes shimmer {
      0% {
        left: -150%;
      }
      100% {
        left: 100%;
      }
    }
  </style>
  
  <div class="skeleton" style="--width: {width}; --height: {height}; --border-radius: {borderRadius};"></div>
  
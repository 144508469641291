<script>
  import SkeletonLoader from './SkeletonLoader.svelte'
  export let scheme;
  export let category = "product";
  export let name;
  export let description;
  export let price;
  export let image;
  export let link;
  export let loading;

</script>

<!-- svelte-ignore a11y-click-events-have-key-events -->
<div class="carousel-card" on:click={() => {
    if(link) window.open(link, "_blank")
}} style="width: 100%; cursor: pointer; min-height: 360px; border-radius: 10px; padding: 8px 12px 12px 12px;">
    {#if !loading}
        <div>
            {#if image}
                <div style="margin-block: 5px">
                    <img src={image} alt="cover-image" style="height: 200px; width: 100%; border-radius: 8px; object-fit: cover" />
                </div>
            {/if}
            <div style="width: 90%; display: grid; gap: 5px">
                {#if ['real-estate'].some(val => category?.toLocaleLowerCase()?.includes(val))}
                    {#if price}     
                        <p style="
                            font-size: 16px; 
                            font-weight: 700; 
                            line-height: 24px;
                            overflow: hidden;
                            text-overflow: ellipsis;
                            display: -webkit-box;
                            -webkit-line-clamp: 3; 
                                    line-clamp: 3;
                            -webkit-box-orient: vertical;">
                            {price}
                        </p>
                    {/if}
                    {#if bedrooms || bathrooms || sqft}
                        <p style="font-size: 15px; font-weight: 500; color: #718096; line-height: 21px">
                            {#if bedrooms}{bedrooms} bed{/if}
                            {#if bathrooms}{bedrooms && bathrooms ? ' | ' : ''}{bathrooms} bath{/if}
                            {#if sqft}{(bedrooms || bathrooms) && sqft ? ' | ' : ''}{sqft} sqft{/if}
                        </p>
                    {/if}
                    {#if name}
                    <p style="
                        font-size: 14px; 
                        font-weight: 400; 
                        color: #1A202C; 
                        line-height: 21px;  
                        overflow: hidden;
                        text-overflow: ellipsis;
                        display: -webkit-box;
                        -webkit-line-clamp: 3; 
                                line-clamp: 3;
                        -webkit-box-orient: vertical;">
                        {name}
                    </p>
                    {/if}
                {:else}
                    {#if name}     
                        <p style="
                            font-size: 16px; 
                            font-weight: 700; 
                            line-height: 24px;
                            overflow: hidden;
                            text-overflow: ellipsis;
                            display: -webkit-box;
                            -webkit-line-clamp: 3; 
                                    line-clamp: 3;
                            -webkit-box-orient: vertical;">
                            {name}
                        </p>
                    {/if}
                    {#if price}
                        <p style="font-size: 15px; font-weight: 500; color: #718096; line-height: 21px">
                            {price}
                        </p>
                    {/if}
                    {#if description}
                        <p style="
                            font-size: 14px; 
                            font-weight: 400; 
                            color: #1A202C; 
                            line-height: 21px;  
                            overflow: hidden;
                            text-overflow: ellipsis;
                            display: -webkit-box;
                            -webkit-line-clamp: 3; 
                                    line-clamp: 3;
                            -webkit-box-orient: vertical;">
                            {description}
                        </p>
                    {/if}
                {/if}
                
                {#key category}    
                    {#if ['pricing', 'price'].some(val => category?.toLocaleLowerCase()?.includes(val))}
                        <div onclick={() => window.open(link, "_blank")} style="width: 100px; height: 32px; background-color: {scheme.header}; cursor: pointer; display: flex; justify-content: center; align-items: center; border-radius: 4px">
                            <p style="font-size: 14px; font-weight:600; line-height: 16; color: #F7FAFC">
                                Learn more
                            </p>
                        </div>
                    {/if}
                {/key}
            </div>
        </div>
    {:else}
        <div style="margin-block: 5px">
            <SkeletonLoader width={"200px"} height={"200px"} />
            <div style="width: 90%; display: grid; gap: 5px">
                <SkeletonLoader width={"100%"} height={"20px"} />
                <SkeletonLoader width={"100%"} height={"20px"} />
            </div>
        </div>
    {/if}
</div>  

<style>
    .carousel-card:hover {
        background: #F7FAFC;
    }
</style>